//import React from 'react';
import FacebookLogo from "../../assets/images/facebook_black.svg";
import TwitterLogo from "../../assets/images/twitter.svg";
import InstagramLogo from "../../assets/images/instagram.svg";
import LinkdInLogo from "../../assets/images/linkedin.png";

const Footer = () => {
  return (
    <div className="bg-gray-300  text-center">
      <div className="container-div pt-6 px-3 md:flex md:justify-center md:pt-10 md:px-16 lg:w-8/12 mx-auto xl:w-10/12">
        <div className="text-gray-800 text-base py-2">
          MS Modular Kitchen @{new Date().getFullYear()}, All rights reserved
          <div>
            {" "}
            Office Address : D 48/139-C, Misir Pokhara, near PDR Mall,
            Varanasi-221010
          </div>
        </div>
      </div>
      {/* <div className="icon-container flex justify-center w-4/12 md:w-3/12 mx-auto py-3">
        <a href="https://www.facebook.com/vibs2143">
          <img
            className="h-6 w-6 md:h-8 md:w-8 lg:w-10 lg:h-10"
            src={FacebookLogo}
            alt="fb"
          />
        </a>
        <a href="https://www.linkedin.com/in/vaibhav-batham-00278b14">
          <img
            className="h-5 w-5 md:h-7 md:w-7 lg:w-9 lg:h-9"
            src={LinkdInLogo}
            alt="insta"
          />
        </a>
        <a href="https://mobile.twitter.com/fasterhomeservi?lang=en">
          <img
            className="h-5 w-5 md:h-7 text-xs md:w-7 lg:w-9 lg:h-9"
            src={TwitterLogo}
            alt="twitter"
          />
        </a> 
      </div> */}
      <div className="text-gray-600 md:mt-2 text-xs pb-4 lg:pb-8 ">
        <h1> Powered by: Rocket Flyer Technology Private Limited </h1>
        <a
          href="www.rocketflyer.in"
          onClick={() => window.open("https://www.rocketflyer.in", "_blank")}
        >
          (www.rocketflyer.in)
        </a>
      </div>
    </div>
  );
};

export default Footer;
