/* This example requires Tailwind CSS v2.0+ */
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";

import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import FaqList from "./faqList";
import FaqText from "./faqText";

export function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const FAQ: React.FC = () => {
  const [faqs, setFaqs] = useState<{ faqData: any[] }>();
  useEffect(() => {
    axios
      .get("faq.json")
      .then((faqData) => {
        console.log(JSON.stringify(faqData.data, null, 2));
        setFaqs(faqData.data);
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <div className="bg-gray-100">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
          <h2 className="text-center text-3xl font-extrabold text-gray-600 sm:text-4xl">
            Frequently asked questions
          </h2>
          <dl className="mt-6 space-y-6 divide-y divide-gray-200">
            {faqs &&
              faqs.faqData.map((faq) => {
                if (faq["type"] === "text") {
                  return (
                    <FaqText question={faq.question} answer={faq.answer} />
                  );
                }

                if (faq["type"] === "list") {
                  return (
                    <FaqList question={faq.question} answers={faq.answer} />
                  );
                }

                return null;
              })}
          </dl>
        </div>
      </div>
    </div>
  );
};
export default FAQ;
