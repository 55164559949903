import { FC, useState } from "react";
import { SomethingWentWrong } from "../something-went-wrong";
import { ThankYou } from "../thankyou";
import { Verification } from "../verification";
import UserDetailsForm from "./user-details-form";

const BookingRequest: FC = () => {
  const [screen, setScreen] = useState<"details" | "verification" | "thankyou" | "somethingWentWrong" >(
    "details"
  );

  if (screen === "details") {
    return <UserDetailsForm setScreen={setScreen} />;
  }

  if (screen === "verification") {
    return <Verification setScreen={setScreen} />;
  }

  if (screen === "thankyou") {
    return <ThankYou />;
  }
  if (screen === "somethingWentWrong") {
    return <SomethingWentWrong setScreen={setScreen}/>;
  }

  return <div></div>;
};

export default BookingRequest;
