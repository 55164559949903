import PickTime from "../assets/images/pick-time.svg";
import BookService from "../assets/images/book-service.svg";
import ProGuy from "../assets/images/pro-guy.svg";
import VideoPlayer from "./video/video";
import { useEffect, useState } from "react";
import axios from "axios";

const OurWork = () => {
  const [data, setData] = useState<{
    works: { workId: number; imgSrc: string }[];
  }>();
  useEffect(() => {
    axios
      .get("ourwork.json")
      .then((workData) => {
        console.log(JSON.stringify(workData.data, null, 2));
        setData(workData.data);
      })
      .catch((err) => console.error(err));
  }, []);

  const products = [
    {
      id: 1,
      name: "Earthen Bottle",
      href: "#",
      price: "$48",
      imageSrc:
        "https://tailwindui.com/img/ecommerce-images/category-page-04-image-card-01.jpg",
      imageAlt:
        "Tall slender porcelain bottle with natural clay textured body and cork stopper.",
    },
    {
      id: 2,
      name: "Nomad Tumbler",
      href: "#",
      price: "$35",
      imageSrc:
        "https://tailwindui.com/img/ecommerce-images/category-page-04-image-card-02.jpg",
      imageAlt:
        "Olive drab green insulated bottle with flared screw lid and flat top.",
    },
    {
      id: 3,
      name: "Focus Paper Refill",
      href: "#",
      price: "$89",
      imageSrc:
        "https://tailwindui.com/img/ecommerce-images/category-page-04-image-card-03.jpg",
      imageAlt:
        "Person using a pen to cross a task off a productivity paper card.",
    },
    {
      id: 4,
      name: "Machined Mechanical Pencil",
      href: "#",
      price: "$35",
      imageSrc:
        "https://tailwindui.com/img/ecommerce-images/category-page-04-image-card-04.jpg",
      imageAlt:
        "Hand holding black machined steel mechanical pencil with brass tip and top.",
    },
    // More products...
  ];

  return (
    <div className="bg-gray-200">
      <header className="lg:pt-12 lg:pb-4 pt-12">
        <div className="flex flex-col items-center">
          <h1 className="text-gray-700 pb-1 lg:pb-0 text-center text-2xl lg:text-5xl lg:font-semibold">
            Our Work
          </h1>
        </div>
      </header>
      <div className="max-w-2xl mx-auto py-4 px-2 sm:py-24 sm:px-3 lg:max-w-7xl lg:px-4">
        <h2 className="sr-only">Our Work</h2>

        <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 gap-x-4 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-4">
          {data &&
            data.works.map((work) => (
              <div
                key={work.workId}
                className="border-2 border-gray-500 w-full aspect-w-1 aspect-h-1 bg-gray-200 rounded-lg overflow-hidden xl:aspect-w-7 xl:aspect-h-8"
              >
                <img
                  src={work.imgSrc}
                  alt={work.workId.toString()}
                  className="h-40 object-center object-cover group-hover:opacity-75"
                  style={{ width: "100%" }}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default OurWork;
