import Confirmed from "../../assets/images/thank-you.svg";
import { useHistory } from "react-router-dom";
import { useApp } from "../context/app-context";
const ThankYou = () => {
  const history = useHistory();
  const {
    onEnterName,
    onEnterMobile,
    selectedService,
    selectedCategory,
    onEnterAddress,
    onEnterDescription,
    onEnterServiceDate,
  } = useApp();


  return (
    <div>
      <img
        className="mx-auto lg:h-44 lg:w-44 h-40 w-40"
        src={Confirmed}
        alt={"thank-you"}
      />
      <h1 className="font-semibold text-xl lg:text-2xl  mb-4 text-blue-500">
        Thank you
      </h1>
      <p className="text-base w-7/12  mx-auto lg:text-lg text-gray-500">
        Your service has been booked successfully. Please visit service section
        for more updates
      </p>
      <div>
        <button
          onClick={() => {
            history.push("/");
           onEnterMobile("");
          selectedService("");
          selectedCategory("");
          onEnterAddress("");
          onEnterDescription("");
          onEnterServiceDate("");
          onEnterName("");
          }}
          className="px-5 py-1 lg:px-8 lg:py-1.5 bg-blue-500 hover:bg-blue-600 text-lg lg:text-xl text-white rounded"
        >
          Ok
        </button>
      </div>
    </div>
  );
};
export default ThankYou;
