import * as React from "react";
import { useEffect } from "react";
import { ServiceCategory } from "../../models/ServiceCategory";
import { ServiceInfo } from "../../models/ServiceInfo";
type Tlocation = {
  address: string;
  location: {
    latitude: number;
    longitude: number;
  };
};
type AppAction =
  | {
      type: "SELECTED_SERVICE_ID";
      payload: string;
    }
  | {
      type: "SELECTED_CATEGORY_ID";
      payload: string;
    }
  | {
      type: "ENTER_NAME";
      payload: string;
    }
  | {
      type: "ENTER_MOBILE";
      payload: string;
    }
  | {
      type: "ENTER_CITY";
      payload: string;
    }
  | {
      type: "ENTER_ADDRESS";
      payload: string;
    }
  | {
      type: "ENTER_SERVIC_DATE";
      payload: string;
    }
  | {
      type: "SERVICES";
      payload: ServiceInfo[];
    }
  | {
      type: "CATEGORIES";
      payload: ServiceCategory[];
    }
  | {
      type: "LOGIN_MOBILE";
      userMobileNumber: number;
    }
  | {
      type: "LOGIN_OTP";
      userOtp: number;
    }
  | {
      type: "ENTER_DESCRIPTION";
      payload: string;
    }
  | {
      type: "ENTER_LOCATION";
      payload: Tlocation;
    };

type AppContextState = {
  location: Tlocation;
  name: string;
  mobile: string;
  city: string;
  address: string;
  description: string;
  serviceDate: string;
  selectedServiceId: string;
  selectedCategoryId: string;
  services: ServiceInfo[];
  categories: Array<ServiceCategory>;
  selectedService: (serviceId: string) => void;
  selectedCategory: (categoryId: string) => void;
  loadServices: (services: ServiceInfo[]) => void;
  onEnterName: (name: string) => void;
  onEnterMobile: (mobile: string) => void;
  onEnterCity: (city: string) => void;
  onEnterAddress: (address: string) => void;
  onEnterDescription: (description: string) => void;
  onEnterServiceDate: (serviceDate: string) => void;
  onEnterLocation: (location: Tlocation) => void;
  loginMobile: number;
  loginOtp: number;
  onLoginMobileChange: (mobile: number) => void;
  onLoginOtpChange: (otp: number) => void;
};

type AppProviderProps = { children: React.ReactNode };

const initialState: AppContextState = {
  location: {
    address: "",
    location: {
      latitude: 0,
      longitude: 0,
    },
  },
  name: "",
  mobile: "",
  city: "",
  address: "",
  description: "",
  serviceDate: "",
  selectedServiceId: "",
  selectedCategoryId: "",
  services: [],
  categories: [],
  selectedService: (serviceId: string) => {},
  selectedCategory: (categoryId: string) => {},
  loadServices: (services: ServiceInfo[]) => {},
  onEnterName: (name: string) => {},
  onEnterMobile: (mobile: string) => {},
  onEnterCity: (city: string) => {},
  onEnterAddress: (address: string) => {},
  onEnterDescription: (description: string) => {},
  onEnterServiceDate: (serviceDate: string) => {},
  onEnterLocation: (location: Tlocation) => {},
  loginMobile: 0,
  loginOtp: 0,
  onLoginMobileChange: (mobile: number) => {},
  onLoginOtpChange: (otp: number) => {},
};

const AppStateContext = React.createContext<AppContextState>(initialState);

AppStateContext.displayName = "AppContext";

function appContextReducer(state: AppContextState, action: AppAction) {
  switch (action.type) {
    case "SELECTED_SERVICE_ID": {
      return {
        ...state,
        selectedServiceId: action.payload,
      };
    }
    case "SELECTED_CATEGORY_ID": {
      return {
        ...state,
        selectedCategoryId: action.payload,
      };
    }
    case "SERVICES": {
      return {
        ...state,
        services: action.payload,
      };
    }
    case "CATEGORIES": {
      return {
        ...state,
        categories: action.payload,
      };
    }
    case "ENTER_NAME": {
      return {
        ...state,
        name: action.payload,
      };
    }
    case "ENTER_MOBILE": {
      return {
        ...state,
        mobile: action.payload,
      };
    }
    case "ENTER_CITY": {
      return {
        ...state,
        city: action.payload,
      };
    }
    case "ENTER_ADDRESS": {
      return {
        ...state,
        address: action.payload,
      };
    }
    case "ENTER_SERVIC_DATE": {
      return {
        ...state,
        serviceDate: action.payload,
      };
    }
    case "LOGIN_MOBILE": {
      return {
        ...state,
        loginMobile: action.userMobileNumber,
      };
    }
    case "LOGIN_OTP": {
      return {
        ...state,
        loginOtp: action.userOtp,
      };
    }
    case "ENTER_DESCRIPTION": {
      return {
        ...state,
        description: action.payload,
      };
    }
    case "ENTER_LOCATION": {
      return {
        ...state,
        location: action.payload,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
}

function AppContextProvider({ children }: AppProviderProps) {
  useEffect(() => {
    //console.log("App Context ....");
  }, []);
  const [state, dispatch] = React.useReducer(appContextReducer, initialState);

  const selectedService = (serviceId: string) => {
    dispatch({ type: "SELECTED_SERVICE_ID", payload: serviceId });
    if (serviceId) {
      const categories = state.services.find(
        (s) => s.serviceId === serviceId
      )?.categories;
      loadCategories(categories || []);
    } else {
      loadCategories([]);
      selectedCategory("");
    }
  };

  const selectedCategory = (categoryId: string) =>
    dispatch({ type: "SELECTED_CATEGORY_ID", payload: categoryId });

  const loadServices = (services: ServiceInfo[]) =>
    dispatch({ type: "SERVICES", payload: services });

  const loadCategories = (categories: ServiceCategory[]) =>
    dispatch({ type: "CATEGORIES", payload: categories });

  const onEnterName = (name: string) =>
    dispatch({ type: "ENTER_NAME", payload: name });
  const onEnterMobile = (mobile: string) =>
    dispatch({ type: "ENTER_MOBILE", payload: mobile });

  const onEnterCity = (city: string) =>
    dispatch({ type: "ENTER_CITY", payload: city });
  const onEnterAddress = (address: string) =>
    dispatch({ type: "ENTER_ADDRESS", payload: address });
  const onEnterServiceDate = (serviceDate: string) =>
    dispatch({ type: "ENTER_SERVIC_DATE", payload: serviceDate });

  const onLoginMobileChange = (mobile: number) => {
    dispatch({ type: "LOGIN_MOBILE", userMobileNumber: mobile });
  };
  const onLoginOtpChange = (otp: number) => {
    dispatch({ type: "LOGIN_OTP", userOtp: otp });
  };
  const onEnterDescription = (description: string) => {
    dispatch({ type: "ENTER_DESCRIPTION", payload: description });
  };

  const onEnterLocation = (location: Tlocation) => {
    dispatch({ type: "ENTER_LOCATION", payload: location });
  };
  const values = React.useMemo(
    () => ({
      ...state,
      selectedService,
      selectedCategory,
      loadServices,
      onEnterMobile,
      onEnterName,
      onEnterAddress,
      onEnterCity,
      onEnterServiceDate,
      onLoginMobileChange,
      onLoginOtpChange,
      onEnterDescription,
      onEnterLocation,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  // console.log("App State:", state);
  return (
    <AppStateContext.Provider value={{ ...values }}>
      {children}
    </AppStateContext.Provider>
  );
}

function useApp() {
  const context = React.useContext(AppStateContext);
  if (context === undefined) {
    throw new Error("useApp must be used within a AppContextProvider");
  }
  return context;
}
export { AppContextProvider, useApp };
