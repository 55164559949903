import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

declare module "axios" {
  interface AxiosResponse<T = any> extends Promise<T> {}
}

export abstract class HttpClient {
  protected readonly instance: AxiosInstance;

  public constructor(baseURL: string) {
    this.instance = axios.create({
      baseURL,
    });
    this._initializeRequestInterceptor();
    this._initializeResponseInterceptor();
  }

  private _initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(
      this._handleRequest,
      this._handleError
    );
  };

  private _initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(
      this._handleResponse,
      this._handleError
    );
  };

  private _handleRequest = (config: AxiosRequestConfig) => {
    config.headers["Content-Type"] = "application/json";
    config.headers["tracki-ai"] = process.env.REACT_APP_TRACKI_AI;
    // config.headers["login-token"] = process.env.REACT_APP_LOGIN_TOKEN;
    // config.headers["tracki-at"] = process.env.REACT_APP_TRACKI_AT;
    // config.headers["tracki-ts"] = process.env.REACT_APP_TRACKI_TS;

    return config;
  };

  private _handleResponse = ({ data }: AxiosResponse) => data;

  protected _handleError = (error: any) => Promise.reject(error);
}
