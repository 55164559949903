import React from "react";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { PhoneIcon } from "../../components/commons/icons/more/phone-icon";
import { UserIcon } from "../../components/commons/icons/more/user-icon";
import { useApp } from "../../components/context/app-context";

import { ServiceInfo } from "../../models/ServiceInfo";

import { validateMobile, validateName } from "../../utils/common-functions";

import dataService from "../../services/data.service";
import { ImgData } from "../../models/ImgData";

type TBannerProps = {
  services: ServiceInfo[];
  selectedServiceId: string;
  onSelect: (serviceId: string) => void;
};

const Banner: React.FC<TBannerProps> = ({
  services,
  selectedServiceId,
  onSelect,
}) => {
  const {
    selectedCategoryId,
    categories,
    selectedCategory,
    name,
    mobile,
    onEnterName,
    onEnterMobile,
  } = useApp();
  const history = useHistory();
  //console.log("category", selectedCategoryId, "service", selectedServiceId)
  const [buttonDisabled, setButtonDisabled] = useState(true);
  //classes for button styling
  const disabledButtonForSmallerScreens: string =
    "xl:hidden lg:mt-8 mb-8 bg-blue-300  focus:outline-none text-white rounded-md px-8 py-3 mt-4 lg:px-12 lg:py-3";
  const enabledButtonForSmallerScreens: string =
    "xl:hidden lg:mt-8 mb-8 bg-blue-500 hover:bg-blue-600 focus:outline-none text-white rounded-md px-8 py-3 mt-4 lg:px-12 lg:py-3";

  const disabledButtonForLargerScreens: string =
    "hidden xl:flex xl:ml-4 bg-blue-300 focus:outline-none text-white rounded-md px-8 py-3  xl:px-4 xl:py-3";
  const enabledButtonForLargerScreens: string =
    "hidden xl:flex xl:ml-4 bg-blue-500 hover:bg-blue-600 focus:outline-none text-white rounded-md px-4 py-3 xl:px-4 xl:py-3";

  const [imgUrl, setImgUrl] = useState<ImgData>();
  useEffect(() => {
    dataService.getImgUrls().then((response) => setImgUrl(response));
  }, []);

  useEffect(() => {
    if (!selectedCategoryId) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
    return;
  }, [selectedCategoryId]);

  const handleSubmit = () => {
    // validation of service dropdown, name and phone
    if (selectedServiceId && selectedCategoryId) {
      history.push("/booking");
    }
  };

  const handleNameOnChange = (name: string) => {
    onEnterName(name);
  };

  const handleMobileOnChange = (mobile: string) => {
    onEnterMobile(mobile);
  };
  //const [closeNav, setCloseNav] = useState(false);
  const [navColor, setNavColor] = useState(false);

  const changeNavbarColor = () => {
    if (window.scrollY >= 40) {
      setNavColor(true);
    } else {
      setNavColor(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  const bannerUrl = imgUrl ? imgUrl.bannerImg : "";
  return (
    <div
      style={{ backgroundImage: `url(${bannerUrl})` }}
      className="h-8/12 w-screen md:h-screen bg-center overflow-hidden bg-no-repeat bg-cover lg:h-screen lg:w-screen lg:bg-center xl:h-screen xl:w-screen"
    >
      <div className="relative  lg:h-screen lg:w-screen lg:items-center lg:flex">
        <main className="pt-10 text-center m-10 lg:pt-28 xl:pt-32 xl:pr-6 ">
          <div className="flex flex-col items-center bg-opacity-70 bg-white p-5">
            <h1 className="text-xl mb-1.5 lg:my-5 font-bold lg:text-5xl xl:text-5xl font-mono text-mango">
              Modular Kitchen & Interior Design
            </h1>
            <h6 className="text-gray-900  lg:py-4 lg:w-8/12 xl:w-6/12 select-none text-lg lg:text-3xl text-center">
              Transform your any shape simple kitchen to modern modular kitchen
              that can be customised to fit into your exisitng space and budget.
            </h6>
          </div>
          {/* <div className="form-container pb-4 lg:pb-0 pt-3 lg:pt-12 xl:pt-16">
          <div className="lg:flex md:flex md:flex-col lg:flex-row ">
            <div>
              <select
                value={selectedServiceId}
                onChange={(e) => onSelect(e.target.value)}
                className="bg-white pr-4 w-64 lg:w-56 xl:w-64 my-2 rounded px-4 py-3 lg:mr-6 lg:my-0.5 lg:px-5 lg:py-2.5"
              >
                <option value="">Select Service</option>
                {services.map((service) => (
                  <option key={service.serviceId} value={service.serviceId}>
                    {service.name}
                  </option>
                ))}
              </select>
            </div>

            <select
              value={selectedCategoryId}
              onChange={(e) => {
                selectedCategory(e.target.value);
              }}
              className="bg-white pr-4 w-64 lg:w-6/12 xl:w-60  rounded px-4 py-3 lg:mr-6 lg:my-0.5 lg:px-5 lg:py-2.5"
            >
              <option className="text-sm" value="">
                Select Service Category
              </option>
              {categories.map((cat) => (
                <option key={cat.id} value={cat.id}>
                  {cat.name}
                </option>
              ))}
            </select>

            <div className="flex my-2 w-64 lg:mr-6 lg:w-44 xl:w-60 lg:my-0">
              <div className="bg-white lg:mt-0.5 lg:px-1 px-2 pt-3 lg:pt-3 lg:pb-3 lg:mb-0.5 lg:pl-2 border lg:h-12 rounded-tl rounded-bl border-white  lg:w-12">
                <UserIcon />
              </div>
              <input
                className="lg:px-4 focus:ring-0 w-full lg:w-44 xl:w-60 rounded-tr rounded-br lg:rounded-tr lg:rounded-br border-none px-4 lg:my-0.5 py-3"
                type="text"
                value={name}
                placeholder={"Your Name"}
                onKeyPress={(event) => validateName(event)}
                onChange={(e) => handleNameOnChange(e.target.value)}
              />
            </div>
            <div className="flex my-y w-64 lg:w-60 lg:my-0">
              <div className="bg-white lg:mt-0.5 lg:px-1 px-2 pt-3 lg:pt-3 lg:pb-3 border lg:h-12 border-white rounded-tl rounded-bl  lg:pl-2 lg:w-12">
                <PhoneIcon />
              </div>
              <input
                id="myMobileInput"
                className="lg:px-4 focus:ring-0 w-full lg:w-44 border-none px-4 rounded-tr rounded-br lg:my-0.5 py-3"
                type="tel"
                value={mobile}
                placeholder={"Your Number"}
                onKeyPress={(event) => validateMobile(event)}
                onChange={(e) => handleMobileOnChange(e.target.value)}
                maxLength={10}
              />
            </div>
            <div className="flex">
              <button
                type="button"
                disabled={selectedCategoryId === ""}
                onClick={handleSubmit}
                className={
                  buttonDisabled
                    ? enabledButtonForLargerScreens
                    : disabledButtonForLargerScreens
                }
              >
                Book Now
              </button>
            </div>
          </div>

          <button
            className={
              buttonDisabled
                ? enabledButtonForSmallerScreens
                : disabledButtonForSmallerScreens
            }
            type="button"
            disabled={selectedCategoryId === ""}
            onClick={handleSubmit}
          >
            Book Now
          </button>
        </div> */}
        </main>
      </div>
    </div>
  );
};

export default Banner;
