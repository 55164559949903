export const validateMobile = (evt: any) => {
  const theEvent = evt || window.event;
  let key;
  //let numberKey:any;
  // Handle  paste
  if (theEvent.type === "paste") {
    key = evt.clipboardData.getData("text/plain");
   //key = evt.onPaste.preventDefault();
  } else {
    // Handle key press
    key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
   //numberKey = parseInt(key);
    //console.log(numberKey, key)
  }
  var regex = /[0-9]|\./;
  //var regex = /^[0-9\b]+$/;
  if (!regex.test(key)) {
    theEvent.returnValue = false;
    if (theEvent.preventDefault) theEvent.preventDefault();
  };

};

export  const validateName = (evt: any) => {
    const theEvent = evt || window.event;
  let key;
    key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);

    var regex = /^[a-zA-Z]*$/g;
  if (!regex.test(key) ) {
    theEvent.returnValue = false;
    if (theEvent.preventDefault) theEvent.preventDefault();
  }
};
