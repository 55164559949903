import Warning from "../../assets/images/warning.svg";
import { useHistory } from "react-router-dom";
type TProps = {
    setScreen: React.Dispatch<
      React.SetStateAction<"details" | "verification" | "thankyou" | "somethingWentWrong">
    >;
  };


const SomethingWentWrong: React.FC<TProps> = ({ setScreen }) => {
  //const history = useHistory();
  return (
    <div>
      <img
        className="mx-auto lg:h-44 lg:mb-12 mb-8 lg:w-44 h-40 w-40"
        src={Warning}
        alt={"thank-you"}
      />
      <h1 className="font-semibold text-xl lg:text-2xl mb-6 text-blue-500">
        Something went wrong
      </h1>
      <p className="text-base w-7/12 mb-6  mx-auto lg:text-lg text-gray-500">
        Please try again.
      </p>
      <div>
        <button
          onClick={() => {
              //history.push("/");
              setScreen("verification");
            }}
          className="px-5 py-1 lg:px-8 lg:py-1.5 bg-blue-500 hover:bg-blue-600 text-lg lg:text-xl text-white rounded"
        >
          Go back
        </button>
      </div>
    </div>
  );
};
export default SomethingWentWrong;
