import { ContactUsApiRequest } from "../models/ContactUsApiRequest";
import { CreateServiceApiRequest } from "../models/CreateServiceApiRequest";
import { GetAllServiceCategoriesApiResponse } from "../models/GetAllServiceCategoriesApiResponse";
import { GetAllServicesApiResponse } from "../models/GetAllServicesApiResponse";
import { GetCategoryInfoApiRepone } from "../models/GetCategoryInfoApiRepone";
import { GetCityMapResponse } from "../models/GetCityMapResponse";
import { RocketflowBaseResponse } from "../models/RocketflowBaseResponse";
import { TrackISendOtpApiRequest } from "../models/TrackISendOtpApiRequest";
import { TrackISendOtpApiResponse } from "../models/TrackISendOtpApiResponse";
import { TrackIVerifyMobileApiRequest } from "../models/TrackIVerifyMobileApiRequest";
import { TrackIVerifyMobileResponse } from "../models/TrackIVerifyMobileResponse";
import { HttpClient } from "./http-client";

type TQueryParam = {
  key: string;
  value: string | string[] | boolean | number | Date | undefined;
};

class FasterHomeService extends HttpClient {
  public constructor() {
    super(`${process.env.REACT_APP_API_URL}`);
  }

  /**
   *
   * @summary Api to get all service categories
   */
  public categoriesUsingGET2 = async (customHeaders: Object = {}) => {
    try {
      const path = "/secure/sp/categories".replace(/^\//, "");
      const allQueryParams: TQueryParam[] = [];
      const queryParams = allQueryParams
        .map((param) => `${param.key}=${param.value}`)
        .join("&");

      const url = queryParams === "" ? path : path.concat(`?${queryParams}`);
      let headers = customHeaders;
      const response =
        await this.instance.get<GetAllServiceCategoriesApiResponse>(url, {
          headers: { ...headers },
        });

      return response;
    } catch (err) {
      console.log("Error categoriesUsingGET2 ]", err);
      throw err;
    }
  };
  /**
   *
   * @summary Api to save lead
   * @param request - request
   */
  public contactUsUsingPOST = async (
    request: ContactUsApiRequest,
    customHeaders: Object = {}
  ) => {
    try {
      const path = "/secure/sp/contactus".replace(/^\//, "");
      const allQueryParams: TQueryParam[] = [];
      const queryParams = allQueryParams
        .map((param) => `${param.key}=${param.value}`)
        .join("&");

      const url = queryParams === "" ? path : path.concat(`?${queryParams}`);
      let headers = customHeaders;
      const response = await this.instance.post<RocketflowBaseResponse>(
        url,
        request,
        {
          headers: { ...headers },
        }
      );

      return response;
    } catch (err) {
      console.log("Error contactUsUsingPOST ]", err);
      throw err;
    }
  };
  /**
   *
   * @summary Api to create service request
   * @param request - request
   */
  public createServiceUsingPOST = async (
    request: CreateServiceApiRequest,
    customHeaders: Object = {}
  ) => {
    try {
      const path = "/secure/sp/createRequest".replace(/^\//, "");
      const allQueryParams: TQueryParam[] = [];
      const queryParams = allQueryParams
        .map((param) => `${param.key}=${param.value}`)
        .join("&");

      const url = queryParams === "" ? path : path.concat(`?${queryParams}`);
      let headers = customHeaders;
      const response = await this.instance.post<RocketflowBaseResponse>(
        url,
        request,
        {
          headers: { ...headers },
        }
      );

      return response;
    } catch (err) {
      console.log("Error createServiceUsingPOST ]", err);
      throw err;
    }
  };
  /**
   *
   * @summary Api to get all services provided
   */
  public getAllServicesUsingGET = async (customHeaders: Object = {}) => {
    try {
      const path = "/secure/sp/services".replace(/^\//, "");
      const allQueryParams: TQueryParam[] = [];
      const queryParams = allQueryParams
        .map((param) => `${param.key}=${param.value}`)
        .join("&");

      const url = queryParams === "" ? path : path.concat(`?${queryParams}`);
      let headers = customHeaders;
      const response = await this.instance.get<GetAllServicesApiResponse>(url, {
        headers: { ...headers },
      });

      return response;
    } catch (err) {
      console.log("Error getAllServicesUsingGET ]", err);
      throw err;
    }
  };
  /**
   *
   * @summary Api to get service category details
   * @param categoryId -
   */
  public getCategoryInfoUsingGET = async (
    categoryId?: string,
    customHeaders: Object = {}
  ) => {
    try {
      const path = "/secure/sp/category/detail".replace(/^\//, "");
      const allQueryParams: TQueryParam[] = [
        {
          key: "categoryId",
          value: categoryId,
        },
      ];
      const queryParams = allQueryParams
        .map((param) => `${param.key}=${param.value}`)
        .join("&");

      const url = queryParams === "" ? path : path.concat(`?${queryParams}`);
      let headers = customHeaders;
      const response = await this.instance.get<GetCategoryInfoApiRepone>(url, {
        headers: { ...headers },
      });

      return response;
    } catch (err) {
      console.log("Error getCategoryInfoUsingGET ]", err);
      throw err;
    }
  };
  /**
   *
   * @summary Api to get all cities
   */
  public getCityMapUsingGET = async (customHeaders: Object = {}) => {
    try {
      const path = "/secure/sp/cities".replace(/^\//, "");
      const allQueryParams: TQueryParam[] = [];
      const queryParams = allQueryParams
        .map((param) => `${param.key}=${param.value}`)
        .join("&");

      const url = queryParams === "" ? path : path.concat(`?${queryParams}`);
      let headers = customHeaders;
      const response = await this.instance.get<GetCityMapResponse>(url, {
        headers: { ...headers },
      });

      return response;
    } catch (err) {
      console.log("Error getCityMapUsingGET ]", err);
      throw err;
    }
  };
  /**
   *
   * @summary Api to Send OTP to given mobile.
   * @param request - request
   */
  public sendOtpUsingPOST4 = async (
    request: TrackISendOtpApiRequest,
    customHeaders: Object = {}
  ) => {
    try {
      const path = "/secure/sp/sendOtp".replace(/^\//, "");
      const allQueryParams: TQueryParam[] = [];
      const queryParams = allQueryParams
        .map((param) => `${param.key}=${param.value}`)
        .join("&");

      const url = queryParams === "" ? path : path.concat(`?${queryParams}`);
      let headers = customHeaders;
      const response = await this.instance.post<TrackISendOtpApiResponse>(
        url,
        request,
        {
          headers: { ...headers },
        }
      );

      return response;
    } catch (err) {
      console.log("Error sendOtpUsingPOST4 ]", err);
      throw err;
    }
  };
  /**
   *
   * @summary Api to verify OTP by mobile number
   * @param request - request
   */
  public verifyMobileUsingPOST4 = async (
    request: TrackIVerifyMobileApiRequest,
    customHeaders: Object = {}
  ) => {
    try {
      const path = "/secure/sp/verifyMobile".replace(/^\//, "");
      const allQueryParams: TQueryParam[] = [];
      const queryParams = allQueryParams
        .map((param) => `${param.key}=${param.value}`)
        .join("&");

      const url = queryParams === "" ? path : path.concat(`?${queryParams}`);
      let headers = customHeaders;
      const response = await this.instance.post<TrackIVerifyMobileResponse>(
        url,
        request,
        {
          headers: { ...headers },
        }
      );

      return response;
    } catch (err) {
      console.log("Error verifyMobileUsingPOST4 ]", err);
      throw err;
    }
  };
}

export default new FasterHomeService();
