import axios from "axios";
import { ImgData } from "../models/ImgData";

class DataService {
  public constructor() {}
  public getImgUrls = async (): Promise<ImgData> => {
    const { data } = await axios.get("imageData.json");
    return data as ImgData;
  };
}

export default new DataService();
