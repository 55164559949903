import { useState } from "react";
import fasterHomeService from "../services/faster-home.service";
import { useHistory } from "react-router-dom";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import Mail from "../assets/images/mail.svg";
import validator from "validator";
import { validateMobile, validateName } from "../utils/common-functions";
import {
  MailIcon,
  LocationMarkerIcon,
  PhoneIcon,
} from "@heroicons/react/outline";

const GetInTouchPage = () => {
  //states for getting the input value
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [message, setMessage] = useState("");

  //client side validation
  const [invalidName, setInvalidName] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidMobile, setInvalidMobile] = useState(false);
  const [invalidMessage, setInvalidMessage] = useState(false);

  const [loadThankyou, setLoadThankyou] = useState(false);

  const history = useHistory();

  //handling the form submit
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!name) {
      setInvalidName(true);
    }
    if (!email) {
      setInvalidEmail(true);
    }
    if (mobile.length !== 10) {
      setInvalidMobile(true);
    }
    if (!message) {
      setInvalidMessage(true);
    }

    // console.log("submit form");
    try {
      const resquestParam = {
        email: email,
        fname: name,
        lname: "",
        message: message,
        mobile: mobile,
      };
      // console.log(resquestParam);
      if (name && email && message && mobile.length === 10) {
        const result = await fasterHomeService.contactUsUsingPOST(
          resquestParam
        );
        if (result.successful) {
          console.log("successfully fetched", result);
          setLoadThankyou(true);
        } else {
          console.log("tried but failed");
        }
      }
    } catch (error) {
      console.log("did not even try to get result", error);
    }
  };

  return (
    <div className="min-h-screen bg-white">
      <main className="overflow-hidden">
        {/* Header */}
        <div className="bg-warm-gray-50">
          <div className="py-8 lg:pt-24 lg:pb-14">
            <div className="relative z-10 max-w-7xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8">
              <div className="">
                <h1 className="text-4xl pb-2 text-center lg:text-left font-medium tracking-tight text-gray-600 sm:text-5xl lg:text-6xl">
                  Get In Touch
                </h1>
                <p className="h-1 w-20 mx-auto text-gray-100 bg-blue-500 lg:bg-transparent">
                  .
                </p>
              </div>
              <p className="mt-6 text-center lg:text-left lg:mt-10 text-xl text-warm-gray-500 max-w-3xl">
                If you have any specific requirement or query, we would love to
                assist you in all possible ways. Please feel free to contact us
                at.
              </p>
            </div>
          </div>
        </div>

        {/* Contact section */}
        <section
          className="relative bg-white"
          aria-labelledby="contact-heading"
        >
          <div
            className="absolute w-full h-1/2 bg-warm-gray-50"
            aria-hidden="true"
          />
          {/* Decorative dot pattern */}
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <svg
              className="absolute z-0 top-0 right-0 transform -translate-y-16 translate-x-1/2 sm:translate-x-1/4 md:-translate-y-24 lg:-translate-y-72"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-warm-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
              />
            </svg>
          </div>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="relative bg-white shadow-lg rounded-tr-lg rounded-br-lg">
              <h2 id="contact-heading" className="sr-only">
                Contact us
              </h2>

              <div className="grid grid-cols-1  mb-12 lg:grid-cols-3">
                {/* Contact information */}
                <div className="relative rounded-lg overflow-hidden py-10 lg:py-12 px-6 bg-gradient-to-b from-blue-400 to-blue-500 sm:px-10 xl:p-12">
                  {/* Decorative angle backgrounds */}
                  <div
                    className="absolute inset-0 pointer-events-none sm:hidden"
                    aria-hidden="true"
                  >
                    <svg
                      className="absolute inset-0 w-full h-full"
                      width={343}
                      height={388}
                      viewBox="0 0 343 388"
                      fill="none"
                      preserveAspectRatio="xMidYMid slice"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z"
                        fill="url(#linear1)"
                        fillOpacity=".1"
                      />
                      <defs>
                        <linearGradient
                          id="linear1"
                          x1="254.553"
                          y1="107.554"
                          x2="961.66"
                          y2="814.66"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#fff" />
                          <stop offset={1} stopColor="#fff" stopOpacity={0} />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  <div
                    className="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none sm:block lg:hidden"
                    aria-hidden="true"
                  >
                    <svg
                      className="absolute inset-0 w-full h-full"
                      width={359}
                      height={339}
                      viewBox="0 0 359 339"
                      fill="none"
                      preserveAspectRatio="xMidYMid slice"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z"
                        fill="url(#linear2)"
                        fillOpacity=".1"
                      />
                      <defs>
                        <linearGradient
                          id="linear2"
                          x1="192.553"
                          y1="28.553"
                          x2="899.66"
                          y2="735.66"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#fff" />
                          <stop offset={1} stopColor="#fff" stopOpacity={0} />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  <div
                    className="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none lg:block"
                    aria-hidden="true"
                  >
                    <svg
                      className="absolute inset-0 w-full h-full"
                      width={160}
                      height={678}
                      viewBox="0 0 160 678"
                      fill="none"
                      preserveAspectRatio="xMidYMid slice"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
                        fill="url(#linear3)"
                        fillOpacity=".1"
                      />
                      <defs>
                        <linearGradient
                          id="linear3"
                          x1="192.553"
                          y1="325.553"
                          x2="899.66"
                          y2="1032.66"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#fff" />
                          <stop offset={1} stopColor="#fff" stopOpacity={0} />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  <h3 className="text-lg lg:text-2xl font-medium text-white">
                    Contact Information
                  </h3>
                  <p className="mt-6 lg:mt-4 text-base text-gray-200 max-w-3xl">
                    Fill the details and our team will get back to you in 48
                    hours
                  </p>
                  <dl className="mt-8 space-y-6">
                    <dt>
                      <span className="sr-only">Phone number</span>
                    </dt>
                    <dd className="flex text-base text-teal-50">
                      <PhoneIcon
                        className="flex-shrink-0 w-6 h-6 text-gray-200"
                        aria-hidden="true"
                      />
                      <span className="ml-3">+91-9956881348</span>
                    </dd>
                    {/* <dt>
                      <span className="sr-only">Email</span>
                    </dt>
                    <dd className="flex text-base text-teal-50">
                      <MailIcon
                        className="flex-shrink-0 w-6 h-6 text-gray-200"
                        aria-hidden="true"
                      />
                      <span className="ml-3">Fasterhomeservice@gmail.com</span>
                    </dd> 
                    <dt>
                      <span className="sr-only">Email</span>
                    </dt>*/}
                    <dd className="flex text-base text-teal-50">
                      {/* <MailIcon className="flex-shrink-0 w-6 h-6 text-gray-200" aria-hidden="true" /> */}
                      <LocationMarkerIcon
                        className="flex-shrink-0 w-6 h-6 text-gray-200"
                        aria-hidden="true"
                      />
                      <span className="ml-3">
                        D 48/139-C, Misir Pokhara, Luxa, Near PDR Mall ,
                        Varanasi-221010
                      </span>
                    </dd>
                  </dl>
                </div>

                {/* Contact form */}
                {!loadThankyou && (
                  <div className="pt-2 pb-8 px-6 sm:px-10 lg:col-span-2 xl:py-2 xl:px-10">
                    {/* method="POST" */}
                    <form
                      action="#"
                      method=""
                      className="mt-6 grid grid-cols-1 gap-y-4 lg:gap-y-10 sm:grid-cols-2 sm:gap-x-8"
                    >
                      <div className="sm:col-span-2">
                        {/* <label htmlFor="first-name" className="block text-sm font-medium text-warm-gray-900">
                        First name
                      </label> */}
                        <div className="mt-1 relative">
                          <input
                            type="text"
                            name="name"
                            placeholder="name"
                            id="name"
                            autoComplete="given-name"
                            onKeyPress={(event) => validateName(event)}
                            onChange={(e) => {
                              setInvalidName(false);
                              setName(e.target.value);
                            }}
                            className="lg:py-3 py-2 px-4 block w-full shadow-sm text-warm-gray-900   focus:outline-none focus:border-b focus:border-gray-500 focus:ring-transparent  border-b border-t-0 border-l-0 border-r-0 border-gray-300"
                          />
                          {invalidName && (
                            <p
                              className="absolute text-sm text-red-600"
                              id="mobile-error"
                            >
                              Please enter a name.
                            </p>
                          )}
                          {invalidName && (
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                              <ExclamationCircleIcon
                                className="h-5 w-5 text-red-500"
                                aria-hidden="true"
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      <div>
                        {/* <label htmlFor="email" className="block text-sm font-medium text-warm-gray-900">
                        Email
                      </label> */}
                        <div className="mt-1 relative">
                          <input
                            id="email"
                            name="email"
                            placeholder="email"
                            type="email"
                            autoComplete="email"
                            onChange={(e) => {
                              setEmail(e.target.value);
                              if (validator.isEmail(email)) {
                                setInvalidEmail(false);
                              }
                            }}
                            className="lg:py-3 py-2  px-4 block w-full shadow-sm focus:outline-none focus:border-b focus:border-gray-500 focus:ring-transparent text-warm-gray-900  border-b border-t-0 border-l-0 border-r-0  border-warm-gray-300 "
                          />
                          {invalidEmail && (
                            <p
                              className="absolute text-sm text-red-600"
                              id="mobile-error"
                            >
                              Please enter a email.
                            </p>
                          )}
                          {invalidEmail && (
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                              <ExclamationCircleIcon
                                className="h-5 w-5 text-red-500"
                                aria-hidden="true"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div>
                        <div className="flex justify-between">
                          {/* <label htmlFor="phone" className="block text-sm font-medium text-warm-gray-900">
                          Phone
                        </label> */}
                          {/* <span id="phone-optional" className="text-sm text-warm-gray-500">
                          Optional
                        </span> */}
                        </div>
                        <div className="mt-1 relative">
                          <input
                            type="tel"
                            name="phone"
                            id="phone"
                            autoComplete="tel"
                            placeholder="phone number"
                            maxLength={10}
                            onKeyPress={(event) => validateMobile(event)}
                            onChange={(e) => {
                              setMobile(e.target.value);
                              if (mobile.length === 10) {
                                setInvalidMobile(false);
                              }
                            }}
                            className="lg:py-3 py-2 px-4 block w-full border-b border-t-0 border-l-0 border-r-0 shadow-sm text-warm-gray-900 focus:outline-none focus:border-b focus:border-gray-500 focus:ring-transparent border-warm-gray-300 "
                            aria-describedby="phone-optional"
                          />
                          {invalidMobile && (
                            <p
                              className="absolute  text-sm text-red-600"
                              id="mobile-error"
                            >
                              Please enter mobile no.
                            </p>
                          )}
                          {invalidMobile && (
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                              <ExclamationCircleIcon
                                className="h-5 w-5 text-red-500"
                                aria-hidden="true"
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="sm:col-span-2">
                        <div className="flex justify-between">
                          {/* <label htmlFor="message" className="block text-sm font-medium text-warm-gray-900">
                          Message
                        </label> */}
                          {/* <span id="message-max" className="text-sm text-warm-gray-500">
                          Max. 500 characters
                        </span> */}
                        </div>
                        <div className="mt-1 relative">
                          <textarea
                            id="message"
                            name="message"
                            placeholder="message"
                            rows={1}
                            onChange={(e) => {
                              setMessage(e.target.value);
                              setInvalidMessage(false);
                            }}
                            className="py-3 px-4 block w-full border-b border-t-0 border-l-0 border-r-0 shadow-sm text-warm-gray-900 focus:outline-none focus:border-b focus:border-gray-500 focus:ring-transparent  border-warm-gray-300"
                            aria-describedby="message-max"
                            defaultValue={""}
                          />
                          {invalidMessage && (
                            <p
                              className="absolute text-sm text-red-600"
                              id="mobile-error"
                            >
                              Please enter a message.
                            </p>
                          )}
                          {invalidMessage && (
                            <div className="absolute  inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                              <ExclamationCircleIcon
                                className="h-5 w-5 text-red-500"
                                aria-hidden="true"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="sm:col-span-2 xl:mb-12 lg:mt-4 mt-2 xl:mt-6  sm:flex sm:justify-center">
                        <button
                          onClick={handleSubmit}
                          type="button"
                          className="mt-2  w-full inline-flex items-center justify-center rounded-md px-12 md:px-8 py-3 border border-transparent  shadow-sm text-base font-medium text-white bg-blue-500 hover:bg-blue-600 focus:outline-none  sm:w-auto"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                )}
                {loadThankyou && (
                  <div className="thankyou-message pt-2 rounded-lg px-6 sm:px-10 lg:col-span-2 xl:py-2 xl:px-10">
                    <div className="flex flex-col mt-8 lg:mt-12 items-center">
                      <img
                        className="lg:w-36 lg:h-36 h-24 w-24"
                        src={Mail}
                        alt="mail"
                      />
                      <h1 className="mt-4 text-gray-500 w-8/12 mx-auto text-center">
                        Thanks for contacting us. We have registered your query
                        and our team will get back to you soon.
                      </h1>
                      <button
                        onClick={() => {
                          history.push("/");
                          setLoadThankyou(false);
                        }}
                        className="rounded bg-blue-600 mt-4 mb-8 lg:mb-12 lg:mt-12 -ml-6 lg:-ml-7 text-white  lg:text-xl lg:px-8 lg:py-2.5 px-5 py-1.5"
                      >
                        Go back
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};
export default GetInTouchPage;
