import { useState } from "react";
import NavigationBar from "../components/navigation/navigation-bar";
import { CategoryServiceDetail } from "../components/category-service-detail";

import { BookingRequest } from "../components/booking-request";
import { Footer } from "../components/footer";

const BookingPage = () => {
  const [closeNav, setCloseNav] = useState(false);

  return (
    <div
      onClick={(e) => {
        setCloseNav(false);
      }}
      className="text-5xl text-center"
    >
      <header className="relative  lg:pr-10">
        <div className="navigation-bar z-50">
          <NavigationBar
            closeNav={closeNav}
            setCloseNav={setCloseNav}
            openColor={"text-gray-700"}
            closeColor={"text-gray-100"}
            textColor={"text-gray-500"}
            hoverColor={"text-gray-700"}
            position={"top-0"}
          />
        </div>
      </header>
      <main className="mb-16 z-0 lg:mb-36">
        <div className=" mx-auto px-4 sm:px-6 lg:px-8">
          <div className="relative bg-white ">
            <h2 id="contact-heading" className="sr-only">
              Service Booking
            </h2>

            <div className="flex flex-col lg:mt-16 mt-12 lg:gap-8 lg:flex lg:flex-row ">
              {/* Left Panel */}
              <div className="lg:w-7/12 w-full border border-gray-200">
                <CategoryServiceDetail />
              </div>

              {/* Right Panel */}
              <div className="lg:w-5/12 w-full border lg:py-5 py-2 border-gray-200">
                <BookingRequest />
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default BookingPage;
