import { useEffect, useState } from "react";
import { useApp } from "../context/app-context";
import fasterHomeService from "../../services/faster-home.service";
import { ServiceCategory } from "../../models/ServiceCategory";
import { v4 as uuidv4 } from 'uuid';

const CategoryServiceDetail = () => {
  const { selectedCategoryId } = useApp();
  const [serviceCategory, setServiceCategory] = useState<ServiceCategory>();

  useEffect(() => {
    const run = async () => {
      if (selectedCategoryId) {
        const response = await fasterHomeService.getCategoryInfoUsingGET(
          selectedCategoryId
        );
        if (response.successful) {
          setServiceCategory(response.data);
        }
      }
    };
    run();
  }, [selectedCategoryId]);
 //console.log(serviceCategory)
  return (
    <div className="">
      <div className=" w-full lg:py-10 px-2 py-4  sm:px-2 xl:px-3">
        {serviceCategory ? (
          <div className="card-container flex flex-col">
            <div className=" w-full flex gap-x-3 lg:gap-x-5">
              <div className="  image-container">
                <img
                  className="h-16 w-16 lg:h-32 text-sm lg:w-32 xl:w-36 xl:h-36"
                  src={serviceCategory.images[0]}
                  alt={"product img"}
                />
              </div>

              <div className="heading w-9/12 ml-2 lg:mt-5">
                <h1 className="lg:text-2xl flex text-xl mb-2 lg:mb-4 font-medium text-blue-600">
                  {serviceCategory.name}
                </h1>

                <ul className="text-xs grid grid-cols-2 md:grid-cols-3  lg:gap-x-3 text-gray-600">
                  {serviceCategory.highlights?.map((list) => (
                    <li className="mb-2 text-xs text-left pr-2 font-semibold text-yellow-600 md:text-sm ml-1" key={uuidv4()}>
                      {/* <span className="h-1 w-1 inline-block mb-0.5 mr-1 rounded-full bg-gray-700"></span> */}
                      {list}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className=" lg:mt-4 mt-2 ">
              {/* <h1 className="text-xl lg:w-24 lg:border-b lg:border-gray-500 lg:ml-4 font-light  px-2 lg:text-2xl text-gray-600 mb-3  flex">
                Includes
              </h1> */}
             
              <div className="overflow-y-auto h-44 py-2 lg:h-72">
              {serviceCategory.specifications?.map((detail) => (
                <div key={uuidv4()}
                className=" lg:px-5 px-2">
                  <h1  //key={serviceCategory.id}
                        className="text-left text-base font-medium my-2 lg:my-4 lg:text-lg xl:text-xl">
                        {detail.heading}{" "} 
                      </h1>
                  <p className="text-xs md:text-sm lg:text-base text-left text-gray-700">
                  {detail.content}
                  </p>
                </div>
              ))}
                 </div>
              
              {/* <h1 className="text-xl  lg:text-3xl mt-4 flex text-gray-600 px-2 lg:px-4">
                &#8377;{serviceCategory.price}
              </h1> */}
            </div>
          </div>
        ) : (
          <div className="text-center text-gray-500 text-base h-56"> Loading the requested Service...</div>
        )}
      </div>
    </div>
  );
};
export default CategoryServiceDetail;
