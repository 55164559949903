import React, { useState, useEffect } from "react";
import { useApp } from "../context/app-context";
import fasterHomeService from "../../services/faster-home.service";
import { ActionTypeEnum } from "../../models/ActionTypeEnum";
import { validateMobile } from "../../utils/common-functions";

type TProps = {
  setScreen: React.Dispatch<
    React.SetStateAction<"details" | "verification" | "thankyou" | "somethingWentWrong">
  >;
};

const Verification: React.FC<TProps> = ({ setScreen }) => {
  //classes for resendOTP button
  const {
    mobile,
    name,
    // onEnterName,
    // onEnterMobile,
    // selectedService,
    // selectedCategory,
    // onEnterAddress,
    // onEnterDescription,
    // onEnterServiceDate,
    location,
    address,
    selectedCategoryId,
    serviceDate,
    description,
  } = useApp();
  const activeClass: string =
    "flex mx-auto text-base lg:text-lg text-yellow-500";
  //const disabledClass: string =
   // "flex mx-auto text-base lg:text-lg text-yellow-300";
  const inputOTPClass: string =
    "w-10 md:w-12 lg:w-12 text-center py-2  md:py-3 lg:py-2.5  bg-blue-200 rounded-lg";

  // const [values, setValues] = useState({
  //   otp1: "",
  //   otp2: "",
  //   otp3: "",
  //   otp4: "",
  //   otp5: "",
  //   otp6: "",
  // });

  const initialValues = {
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    otp5: "",
    otp6: "",
  }

  const [values, setValues] = useState(initialValues);
  const [isDisabled, setIsDisabled] = useState(true);
  const [otpError, setOtpError] = useState(false);
  //timer functionality
   const [seconds, setSeconds ] = useState(60);

   useEffect(() => {
    let interval:any = null;
      interval = setInterval(() => {
        setSeconds(seconds => seconds - 1);
      }, 1000);
      if(seconds === 0) {
        clearInterval(interval);
        setIsDisabled(false);
      }
    
    return () => clearInterval(interval);
  }, [ seconds]);

  const handleResendOTP = async () => {
    setIsDisabled(true);
    setSeconds(60)
    //console.log(isDisabled)
    //setTimeout(() => setIsDisabled(false), 15000);
    if (!isDisabled) {
      try {
        const resendOTP = await fasterHomeService.sendOtpUsingPOST4({
          mobile,
          actionType: ActionTypeEnum.LOGIN,
        });
        // console.log(resendOTP);

        if (resendOTP.successful) {
          console.log("Resend OTP result", resendOTP);
        } else console.log("not succesful");
      } catch (error) {
        console.log("can't resend OTP", error);
      }
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  //OTP input focus  jumps on next Input
  const handleKeyUp = async (e: any) => {
    const targetedValue = e.target.value;
    const targetedNumberValue: number = parseInt(targetedValue);
    if (targetedValue) {
      if (targetedValue && e.target.nextElementSibling) {
        e.target.nextElementSibling.focus();
        //console.log("go forward", e)
        //console.log(targetedValue ,typeof(targetedValue));
        //console.log(targetedNumberValue, typeof(targetedNumberValue));
        //console.log(isFinite(targetedValue), isFinite(targetedNumberValue))
      }
       
    }
    if (e.target.previousElementSibling && e.keyCode === 8) {
      //console.log("back jao");
      e.target.previousElementSibling.focus();
    }
    // if(typeof(isFinite(targetedNumberValue))  ) {
    //   e.target.value="";
    //   e.target.focus();
    //   console.log("no idea what is going on")
    // }
  };
  const handleOtpVerification = async () => {
    const otp = `${values.otp1}${values.otp2}${values.otp3}${values.otp4}${values.otp5}${values.otp6}`;
    //resendOTP gets disabled for 15 secs
    //setIsDisabled(true);
    // enable resendOTP button
    setTimeout(() => setIsDisabled(false), 15000);
    if (otp.length === 6) {
      const result = await fasterHomeService.verifyMobileUsingPOST4({
        mobile,
        otp,
      });
      if (result.successful) {
        var d = new Date(serviceDate);
        var longDate = d.getTime();
        const request = {
          verificationId: result.verificationId,
          categoryId: selectedCategoryId,
          serviceDate: longDate,
          location: { ...location },
          description,
          //serviceDate: "08-08-2021",
          //serviceDate,
          //city,
          mobile,
          name,
        };
        

        const res = await fasterHomeService.createServiceUsingPOST(request);
        console.log("create request response: ", res);
        if (res.successful) {
          // onEnterMobile("");
          // selectedService("");
          // selectedCategory("");
          // onEnterAddress("");
          // onEnterDescription("");
          // onEnterServiceDate("");
          // onEnterName("");
          setScreen("thankyou");
          console.log("otp was correct")

        }
        //if create service result is not successfull
         if(!res.successful) {
        //   setScreen("somethingWentWrong");
        console.log("unable to create service as of now");
         }
      }
      // if OTP result is not successful 
      else {
        //add something went wrong component here
        //setScreen("somethingWentWrong");
        console.log("the entered OTP seems to be wrong");
        setOtpError(true);
        setValues(initialValues);
      }
    }
    else {
      console.log("there is an error,6 digit OTP wasn't entered");
        setOtpError(true);
        setValues(initialValues);
        
    }
  };
   
  const handleBackButton = () => {
    setScreen("details");
  }

  return (
    <div className="">
      <div className="py-3.5  w-full  px-6 sm:px-10 lg:col-span-2 xl:py-2 xl:px-10">
        <div className="flex items-center justify-between">
        <div className="" onClick={handleBackButton}>
        <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-500 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
         </svg>
        </div>
          <h3 className="text-xl my-2 lg:my-4 justify-center flex text-blue-600 lg:text-2xl">
            Verification
          </h3>
          <div></div>
        </div>
        <div>
          <h5 className="mt-2 mx-6 text-center text-base md:text-lg lg:text-xl text-gray-600">
            We have sent verification code on <br />
            +91 {mobile}
            <span className="phone-number mt-2 block text-center text-indigo-600"></span>
          </h5>
        </div>
        <div className=" text-center my-12 relative flex flex-row gap-1 justify-center ">
          <input
            required
            value={values.otp1}
            onChange={handleInputChange}
            onKeyPress={(event) => validateMobile(event)}
            onKeyUp={handleKeyUp}
            type={"tel"}
            name="otp1"
            aria-describedby="otp1"
            placeholder=""
            maxLength={1}
            pattern="[1-9]{1}[0-9]{9}"
            className={inputOTPClass}
            tabIndex={1}
          />
          <input
            required
            value={values.otp2}
            onChange={handleInputChange}
            onKeyPress={(event) => validateMobile(event)}
            onKeyUp={handleKeyUp}
            type={"tel"}
            name="otp2"
            aria-describedby="otp2"
            placeholder=""
            maxLength={1}
            pattern="[1-9]{1}[0-9]{9}"
            className={inputOTPClass}
            tabIndex={2}
          />
          <input
            required
            value={values.otp3}
            onChange={handleInputChange}
            onKeyPress={(event) => validateMobile(event)}
            onKeyUp={handleKeyUp}
            type={"tel"}
            name="otp3"
            aria-describedby="otp3"
            placeholder=""
            maxLength={1}
            pattern="[1-9]{1}[0-9]{9}"
            className={inputOTPClass}
            tabIndex={3}
          />
          <input
            required
            value={values.otp4}
            onChange={handleInputChange}
            onKeyPress={(event) => validateMobile(event)}
            onKeyUp={handleKeyUp}
            type={"tel"}
            name="otp4"
            aria-describedby="otp4"
            placeholder=""
            maxLength={1}
            pattern="[1-9]{1}[0-9]{9}"
            className={inputOTPClass}
            tabIndex={4}
          />
          <input
            required
            value={values.otp5}
            onChange={handleInputChange}
            onKeyPress={(event) => validateMobile(event)}
            onKeyUp={handleKeyUp}
            type={"tel"}
            name="otp5"
            aria-describedby="otp5"
            placeholder=""
            maxLength={1}
            pattern="[1-9]{1}[0-9]{9}"
            className={inputOTPClass}
            tabIndex={5}
          />
          <input
            required
            value={values.otp6}
            onChange={handleInputChange}
            onKeyPress={(event) => validateMobile(event)}
            onKeyUp={handleKeyUp}
            type={"tel"}
            name="otp6"
            aria-describedby="otp6"
            placeholder=""
            maxLength={1}
            pattern="[1-9]{1}[0-9]{9}"
            className={inputOTPClass}
            tabIndex={6}
          />
          {otpError && (
            <span className=" absolute text-red-500 text-sm top-11 lg:top-12 lg:text-base">please enter the valid OTP</span>
          )}
          
        </div>
        <div>
          <div className="text-center text-base lg:text-lg text-gray-400 font-semibold mb-1">
            Did not receive the OTP?
            {!isDisabled && (
              <button
              className={activeClass}
              onClick={handleResendOTP}
            >
              {" "}
              Resend OTP
            </button>
            )}
            {isDisabled && (
              <p className="text-base lg:text-lg text-yellow-500" > 00 : {seconds} </p>
            )}
          </div>
        </div>
        <div className="mb-12 w-5/12 lg:w-full mx-auto mt-6">
          <button
            onClick={(e) => handleOtpVerification()}
            className="px-8 py-2  lg:px-10 lg:py-2.5 text-lg lg:text-xl rounded bg-blue-500 hover:bg-blue-600 text-white"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default Verification;
