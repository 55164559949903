import BookingPage from "../../pages/booking-page";
import { Redirect, Route, Switch, RouteComponentProps } from "react-router-dom";
import Home from "../../pages/home/home";
import { useApp } from "../context/app-context";
import LoginPage from "../../pages/login-page";

function App() {
  const { selectedCategoryId } = useApp();
  return (
    <div className="bg-white ">
      <Switch>
        <Route path="/" component={Home} exact />

        <Route
          path="/booking"
          render={(props: RouteComponentProps) => {
            if (selectedCategoryId) {
              return <BookingPage />;
            }
            return <Redirect to="/" />;
          }}
          exact
        />


        <Redirect from="*" to="/" />
      </Switch>

      <div></div>
    </div>
  );
}

export default App;
