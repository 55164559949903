import React, { useState } from "react";
import { useApp } from "../context/app-context";
import fasterHomeService from "../../services/faster-home.service";
import { ActionTypeEnum } from "../../models/ActionTypeEnum";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { validateMobile, validateName } from "../../utils/common-functions";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';

type TProps = {
  setScreen: React.Dispatch<
    React.SetStateAction<"details" | "verification" | "thankyou" | "somethingWentWrong">
  >;
};
const UserDetailsForm: React.FC<TProps> = ({ setScreen }) => {
  const {
    name,
    mobile,
    location,
    address,
    serviceDate,
    description,
    onEnterMobile,
    onEnterName,
    onEnterAddress,
    onEnterLocation,
    onEnterServiceDate,
    onEnterDescription,
  } = useApp();

  //class for submit button
  const enabledClass =
    "mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-500 hover:bg-blue-600 focus:outline-none  focus:border-none  focus:ring-0 sm:w-auto";

    const InputClassName="lg:py-3 py-2 px-4 block w-full shadow-sm text-warm-gray-900 focus:outline-none focus:border-b focus:border-gray-500 focus:ring-transparent  border-b border-t-0 border-l-0 border-r-0 border-gray-400 placeholder-gray-400 placeholder-text-sm";
  //const [date, setDate] = useState("");

  const [invalidName, setInvalidName] = useState(false);
  const [invalidMobile, setInvalidMobile] = useState(false);
  //const [invalidCity, setInvalidCity] = useState(false);
  const [invalidAddress, setInvalidAddress] = useState(false);
  const [coordinates, setCooedinates] = useState({ lat: 0, lng: 0 });

  const handleSelect = async (value: any) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    onEnterAddress(value);
    onEnterLocation({
      address: value,
      location: { latitude: latLng.lat, longitude: latLng.lng },
    });
    setCooedinates(latLng);
    setInvalidAddress(false);
  };


  const minDate: any = new Date();
  //const maxDate: Date =new Date(new Date().getFullYear(), new Date().getMonth(), 27,new Date().getHours(),new Date().getMinutes(),new Date().getSeconds());
  //const dateValue: Date = new Date(new Date().setDate(17));
  //console.log(minDate);

  const handleSaveDetails = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    //console.log(name, mobile, city, serviceDate, address);

    //only for client side validation
    if (!mobile) {
      setInvalidMobile(true);
    }
    if (!name) {
      setInvalidName(true);
    }
    if (!address) {
      setInvalidAddress(true);
    }

    e.preventDefault();
    if (!invalidMobile && !invalidAddress && !invalidName && serviceDate) {
      try {
        //console.log(invalidAddress)
        const result = await fasterHomeService.sendOtpUsingPOST4({
          actionType: ActionTypeEnum.LOGIN,
          mobile,
        });
        if (result.successful) {
          setScreen("verification");
          console.log("result is successful");
        } else {
          console.log("please enter details");
        }
      } catch (error) {
        console.log(error);
      }
    }
    else {
      console.log("not working as intended");
    }
  } 
  
  

  return (
    <div className="">
      <div className="py-4  w-full  px-6 sm:px-10 lg:col-span-2 xl:py-2 xl:px-10">
        <h3 className="text-xl mt-4 flex text-blue-600 lg:text-2xl">Details</h3>
        <div className="mt-2 ">
          <div>
            <div className="my-5 relative">
              <input
                type="text"
                name="name"
                placeholder="Name"
                id="name"
                autoComplete="given-name"
                //className={InputClassName}
                className="lg:py-2.5 py-2 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-blue-500 focus:border-blue-500 border-warm-gray-300 rounded-md"
                value={name}
                onKeyPress={(event) => validateName(event)}
                //onChange={(e) => onEnterName(e.target.value)}
                onChange={(e) => {
                  setInvalidName(false);
                  onEnterName(e.target.value);
                }}
              />
              {invalidName && (
                <p className="absolute text-sm text-red-600" id="mobile-error">
                  Please enter a name.
                </p>
              )}
              {invalidName && (
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <ExclamationCircleIcon
                    className="h-5 w-5 text-red-500"
                    aria-hidden="true"
                  />
                </div>
              )}
            </div>
          </div>
          <div>
            <div className="my-5 relative">
              <input
                type="tel"
                name="mobile"
                placeholder="Mobile no"
                id="mobile"
                maxLength={10}
                //className={InputClassName}
                className="lg:py-2.5 py-2 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-blue-500 focus:border-blue-500 border-warm-gray-300 rounded-md"
                value={mobile}
                onKeyPress={(event) => {
                  validateMobile(event);
                }}
                onChange={(e) => {
                  onEnterMobile(e.target.value);
                  if (mobile.length === 10) {
                    setInvalidMobile(false);
                  }
                }}
              />
              {invalidMobile && (
                <p className="absolute text-sm text-red-600" id="mobile-error">
                  Please enter Mobile.
                </p>
              )}
              {invalidMobile && (
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <ExclamationCircleIcon
                    className="h-5 w-5 text-red-500"
                    aria-hidden="true"
                  />
                </div>
              )}
            </div>
          </div>
          <div>
            <div className="my-5">
               <input
                id="date"
                name="date"
                placeholder="Scheduled Date-time"
                //type="datetime-local"
                type="text"
                min={minDate}
                value={serviceDate}
                onFocus={(e) => (e.target.type = "datetime-local")}
                onBlur={(e) => (e.target.type = "text")}
                autoComplete=""
                className="lg:py-2.5 py-2  px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-blue-500 focus:border-blue-500 border-warm-gray-300 rounded-md"
                onChange={(e) => onEnterServiceDate(e.target.value)}

                //onChange={(e) => setDate(e.target.value) }
              /> 
                 {/* <div
              className=" rounded-md">
              <DateTimePickerComponent 
              //value={serviceDate}
              name="date"
              id="datetimepicker"
              min={minDate}
              //cssClass={customClass}
              placeholder="please choose a date"
              onChange={(e:any) => onEnterServiceDate(e.target.value)} /> 
              
          </div>   */}
          
            </div>
          </div>

          <div>
            <div className="my-5 relative">
              {/* <input
                type="text"
                name="address"
                id="address"
                autoComplete="address"
                placeholder="Address"
                className="lg:py-2.5 py-2 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-blue-500 focus:border-blue-500 border-warm-gray-300 rounded-md"
                aria-describedby="phone-optional"
                value={address}
                //onChange={(e) => onEnterAddress(e.target.value)}
                onChange={(e) => {
                  setInvalidAddress(false);
                  onEnterAddress(e.target.value);
                }}
              />
              {invalidAddress && (
                <p className="absolute text-sm text-red-600" id="mobile-error">
                  Please enter Address.
                </p>
              )}
              {invalidAddress && (
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <ExclamationCircleIcon
                    className="h-5 w-5 text-red-500"
                    aria-hidden="true"
                  />
                </div>
              )} */}
              <PlacesAutocomplete
                value={address}
                onChange={onEnterAddress}
                onSelect={handleSelect}
                debounce={500}
                googleCallbackName={"myCallbackFunc"}
                searchOptions={{
                  types: ["address"],
                  componentRestrictions: { country: "in" },
                }}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <input
                      {...getInputProps({
                        placeholder: "Address",
                        //className:InputClassName
                        className: "lg:py-2.5 py-2 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-blue-500 focus:border-blue-500 border-warm-gray-300 rounded-md",
                      })}
                    />
                    <div className="autocomplete-dropdown-container">
                      {loading && <div className="text-xs">Loading...</div>}
                      {suggestions.map((suggestion) => {
                        //console.log(suggestion);
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? {
                              backgroundColor: "#fafafa",
                              fontSize: 12,
                              border: "1px solid #e7e7e7",
                              borderTop: "none",
                              padding: 5,
                              cursor: "pointer",
                            }
                          : {
                              backgroundColor: "#ffffff",
                              fontSize: 12,
                              border: "1px solid #e7e7e7",
                              borderTop: "none",
                              padding: 5,
                              cursor: "pointer",
                            };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
              {invalidAddress && (
                <p className="absolute text-sm text-red-600" id="mobile-error">
                  Please enter Address.
                </p>
              )}
            </div>

            <div className="my-5 relative">
              <textarea
                rows={2}
                name="description"
                id="description"
                autoComplete="description"
                placeholder="Description "
                //className={InputClassName}
                className="lg:py-2.5 py-2 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-blue-500 focus:border-blue-500 border-warm-gray-300 rounded-md"
                aria-describedby="city"
                value={description}
                onChange={(e) => {
                  onEnterDescription(e.target.value);
                  //console.log(description)
                }}
                //onChange={(e) => {
                //console.log("description")
                //setInvalidCity(false);
                //onEnterCity(e.target.value);
                //}}
              />
            </div>
            </div>

          <div className="sm:col-span-2 sm:flex sm:justify-center">
            <button
              //disabled={!(name && mobile.length=== 10 && serviceDate && city && address)}
              //disabled={isDisabled}
              type="button"
              onClick={(e) => handleSaveDetails(e)}
              className={enabledClass}
            >
              Book now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetailsForm;
