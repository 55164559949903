import React, { useEffect, useState } from "react";
import OurService from "../our-service";
import Banner from "./banner";
import fasterHomeService from "../../services/faster-home.service";
import OurWork from "../../components/our-work";
import { useApp } from "../../components/context/app-context";
import GetInTouchPage from "../get-in-touch-page";
import { FAQ } from "../../components/faq";
import { Footer } from "../../components/footer";
import { NavigationBar } from "../../components/navigation";

const Home = () => {
  const { selectedServiceId, services, loadServices, selectedService } =
    useApp();

  const [closeNav, setCloseNav] = useState(false);

  useEffect(() => {
    const run = async () => {
      const response = await fasterHomeService.getAllServicesUsingGET();
      if (response.successful) {
        loadServices(response.data);
      }
    };

    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //const helloRef = React.createRef<HTMLDivElement>();

  const handleSelect = (serviceId: string) => {
    selectedService(serviceId);
  };
  return (
    <div
      onClick={(e) => {
        setCloseNav(false);
      }}
      id="banner"
      className="overflow-x-hidden"
    >
      <div className=" relative">
        <NavigationBar closeNav={closeNav} setCloseNav={setCloseNav} />
        <Banner
          services={services}
          selectedServiceId={selectedServiceId}
          onSelect={handleSelect}
        />
        <div className="fixed lg:hidden bottom-8 right-4 p-4  bg-blue-500 shadow-2xl  rounded-full z-20">
          <a href="tel:+919956881348">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="text-white h-6 w-6"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
            </svg>
          </a>
        </div>
      </div>

      <div id="services">{/* <OurService services={services} /> */}</div>
      <div id="ourwork">
        <OurWork />
      </div>
      <div id="contactus">
        <GetInTouchPage />
      </div>
      <div id="faq" className=" bg-gray-100 pb-12">
        <FAQ />
      </div>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default Home;
